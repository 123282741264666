<template>
  <div class="justify-center w-full h-full d-flex align-center">
    <v-card
      style="z-index: 2"
      class="pa-4"
      :class="{'rounded-0 h-full': $vuetify.breakpoint.smAndDown}"
    >
      <v-card-text class="d-flex flex-column align-center registration-container content">
        <v-img
          alt="Firmen Logo"
          :src="require('@/../public/img/logo.png')"
          :max-height="100"
          contain
        />
        <div class="mt-4 text-center">
          <span class="title">14 Tage kostenlos testen!</span>
          <p class="mb-8 text-body-2">
            Jetzt loslegen und durchstarten.
          </p>
        </div>
        <div class="mt-4">
          <v-form @submit="signup">
            <v-text-field
              v-model.trim="email"
              label="Ihre E-Mail-Adresse"
              type="email"
              outlined
              hide-details
              class="mb-4"
              prepend-inner-icon="mdi-email-outline"
              required
            />

            <v-text-field
              v-model="password"
              label="Passwort"
              :type="showPassword ? 'text' : 'password'"
              hide-details
              outlined
              prepend-inner-icon="mdi-lock-outline"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              class="mb-4"
              required
              @click:append="showPassword = !showPassword"
            />

            <v-checkbox v-model="checkbox">
              <template v-slot:label>
                <div>
                  Ich stimme den
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        :href="publicTOCandLicenceUrl"
                        @click.stop
                        v-on="on"
                      >Nutzungs- und Lizenzbedingungen</a>
                    </template>
                    Öffnet in neuem Fenster
                  </v-tooltip>
                  sowie den
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <a
                        target="_blank"
                        :href="publicDataPrivacyUrl"
                        @click.stop
                        v-on="on"
                      >Datenschutzbestimmungen</a>
                    </template>
                    Öffnet in neuem Fenster
                  </v-tooltip>
                  zu.
                </div>
              </template>
            </v-checkbox>

            <div
              class="px-0 d-flex flex-column"
            >
              <v-expand-transition>
                <v-alert
                  v-if="error"
                  type="error"
                  dense
                  tile
                  text
                  class="my-4"
                >
                  {{ getErrorMessage(error) }}
                </v-alert>
              </v-expand-transition>
              <loading-button
                color="primary"
                type="submit"
                class="mb-5"
                style="height:50px"
                :loading="submitting"
              >
                Kostenlos testen
              </loading-button>
            </div>
          </v-form>
        </div>
        <div class="mt-4 text-center">
          <span>Sie haben bereits ein Konto?</span>
          <a
            href="/auth/login"
            class="ml-2 primary--text font-weight-bold hover"
          >Anmelden</a>
        </div>
      </v-card-text>
    </v-card>
    <GradientBackground
      :top-left="gradientColors.start"
      :top-right="gradientColors.end"
      :bottom-left="gradientColors.start"
      :bottom-right="gradientColors.end"
      style="z-index: 1"
    />
  </div>
</template>

<script>
import featureMixin from '@/mixins/feature'
import brandingMixin from '@/mixins/branding'
import SIGN_UP_USER from '@/queries/SignUpUser.gql'
import { isValidPassword } from '@/lib/password'
import { email } from '@/lib/validation'

export default {
  components: {
    GradientBackground: () => import('@/components/GradientBackground.vue'),
    LoadingButton: () => import('@/components/loading-button')
  },
  mixins: [featureMixin, brandingMixin],
  data () {
    return {
      submitting: false,
      email: '',
      password: '',
      checkbox: false,
      error: null,
      showPassword: false
    }
  },
  computed: {
    formReady () {
      return (this.email.length && this.password.length)
    },
    errorMessages () {
      return {
        emptyFields: 'Bitte geben Sie Ihre E-Mail-Adresse und ein Passwort an.',
        invalidEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse an.',
        invalidAgreement: 'Bitte stimmen Sie durch die Auswahl der Checkbox den Nutzungs- und Lizenzbedingungen, sowie den Datenschutzbestimmungen zu.',
        invalidPassword: 'Ihr Passwort muss folgendes enthalten: Mind. 8 Zeichen lang. Mind. 3 von: Kleinbuchstaben (a-z), Großbuchstaben (A-Z), Zahlen (0-9), Sonderzeichen (z.B. !@#$%^&*).',
        userExists: 'Ein Konto mit dieser E-Mail-Adresse besteht bereits.',
        unknown: 'Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.'
      }
    }
  },
  apollo: {
    $client: 'public'
  },
  methods: {
    async signup (event) {
      event.preventDefault()
      this.error = null
      if (this.formReady) {
        if (email(this.email) !== true) {
          this.error = 'invalidEmail'
          return
        }
        if (!isValidPassword(this.password)) {
          this.error = 'invalidPassword'
          return
        }
        if (!this.checkbox) {
          this.error = 'invalidAgreement'
          return
        }

        this.submitting = true
        try {
          const { data } = await this.$apollo.mutate({
            mutation: SIGN_UP_USER,
            variables: {
              input: {
                email: this.email,
                password: this.password
              }
            }
          })
          this.submitting = false

          const { status, errorCode } = data.signUpUser

          if (status === 'ERROR') {
            this.error = errorCode
          } else {
            await this.$auth.login(this.email, this.password)
          }
        } catch (error) {
          this.error = 'unknwon'
          this.submitting = false
        }
      } else {
        this.error = 'emptyFields'
      }
    },
    getErrorMessage (code) {
      return this.errorMessages[code] || this.errorMessages.unknown
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  max-width: 450px;
}
</style>
