import { PasswordPolicy, charsets } from 'password-sheriff'

export const isValidPassword = (password) => {
  const policy = new PasswordPolicy({
    length: {
      minLength: 8
    },
    containsAtLeast: {
      atLeast: 3,
      expressions: [charsets.lowerCase, charsets.upperCase, charsets.numbers, charsets.specialCharacters]
    }
  })
  return policy.check(password)
}
